import { all, put, call, takeLatest, select, delay } from 'redux-saga/effects';
import UmsLogic, { LoginResponse } from '@biot/ums-js-logic';
import { actions as loginActions } from 'src/redux/data/login/modules/slice';

import { PortalTypeEnum } from '@biotmed/data-components';
import { getSdkApi } from '@biotmed/sdk-api-provider';
import { AxiosResponse } from 'axios';
import Flags from '@biotmed/flags-lib';
import { GetViewWithFullInfoResponse, SystemPropertiesResponse, ViewTypeEnum } from '@biotmed/settings-sdk';
import { ErrorTypeEnum, errorNotice } from '@biotmed/system-notifications';
import { actions, selectors } from './slice';
import AppConfig from '../../../../config/AppConfig';

function* handleLogin() {
  const isLoggedIn: boolean = yield call(UmsLogic.isLoggedIn);

  if (!isLoggedIn) {
    return;
  }

  try {
    const response: LoginResponse = yield call(UmsLogic.appStartLogin);
    yield put(loginActions.loginSuccess({ loginResponse: response }));
  } catch (e) {
    console.error('Error while trying to login with token: ', e);
  }
}

function* waitForMessages() {
  let loaded: boolean = yield select(selectors.selectLocaleSetupFinished);
  while (!loaded) {
    yield delay(1000);
    loaded = yield select(selectors.selectLocaleSetupFinished);
  }
}

function* getMainTabs() {
  try {
    const { data }: AxiosResponse<GetViewWithFullInfoResponse> = yield call(
      getSdkApi().settings.portalBuilderApi.getViewWithFullInfo,
      PortalTypeEnum.MANUFACTURER_PORTAL,
      ViewTypeEnum.Main,
    );

    yield put(actions.onMainViewBuilderFetch(data));
  } catch (e) {
    console.error('Error while trying to fetch main tabs', e);
  }
}

function* initFlags() {
  try {
    yield call([Flags, Flags.init], AppConfig.EPIC_RELEASE_FLAGS_URL);
  } catch (e) {
    console.log('Error while trying to init flags-lib ', e);
  }
}

function* fetchBiotVersion() {
  try {
    const {
      data: { biotVersion },
    }: AxiosResponse<SystemPropertiesResponse> = yield call(getSdkApi().settings.systemApi.getSystemProperties);
    yield put(actions.onBiotVersionFetch(biotVersion));
  } catch (e: any) {
    yield put(errorNotice({ type: ErrorTypeEnum.GENERAL, errorParams: { error: e?.response?.data || e } }));
  }
}

function* onAppStart() {
  yield all({
    initFlags: call(initFlags),
    loginResult: call(handleLogin),
    fetchMessagesSuccessResult: call(waitForMessages),
    fetchBiotVersionSuccessResult: call(fetchBiotVersion),
  });

  yield put(actions.appStartFinish(false));
}

export default function* watchAppActions() {
  yield all([takeLatest(actions.appStart, onAppStart), takeLatest(loginActions.loginSuccess, getMainTabs)]);
}
