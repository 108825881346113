// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@biotmed/base-components';

import { actions as appActions } from '../../../redux/data/app/modules/slice';
import { selectors as appSelectors } from '../../../redux/data/app';
import { selectors as loginSelectors } from '../../../redux/data/login/modules/slice';
import RoutesEnum from '../modules/routes';
import { PrivatePage, PublicPage } from './Routes';
import { Spin, SpinBox } from './styled';

interface NavigationProps {}

const Navigation: React.FC<NavigationProps> = () => {
  const isLoggedIn = !!useSelector(loginSelectors.getIsLoggedIn);
  const isAppLoading = useSelector(appSelectors.getIsAppLoading);

  const dispatch = useDispatch();

  const _onAppStart = () => {
    dispatch(appActions.appStart());
  };

  // eslint-disable-next-line
  useEffect(_onAppStart, []);

  return (
    <>
      {isAppLoading ? (
        <SpinBox>
          <Spin />
        </SpinBox>
      ) : (
        <Snackbar>
          <Routes>
            <Route caseSensitive path={`/${RoutesEnum.AUTH}/*`} element={<PublicPage isLoggedIn={isLoggedIn} />} />
            <Route path="/*" element={<PrivatePage isLoggedIn={isLoggedIn} />} />
          </Routes>
        </Snackbar>
      )}
    </>
  );
};

Navigation.defaultProps = {
  isLoggedIn: false,
  isAppLoading: false,
};

export default Navigation;
