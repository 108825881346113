import { EnvType } from '@biot/env';

declare global {
  interface Window {
    env: any;
  }
}

const env: EnvType = { ...process.env, ...window.env };

const APP_CONFIG = Object.freeze({
  ...process.env,
  API_URL: env.REACT_APP_API_URL,
  APP_DOMAIN: env.REACT_APP_DOMAIN,
  PLATFORMS_CONSOLE_URL: env.REACT_APP_PLATFORMS_CONSOLE_URL,
  PLATFORMS_MANUFACTURER_URL: env.REACT_APP_PLATFORMS_MANUFACTURER_URL,
  PLATFORMS_ORGANIZATION_URL: env.REACT_APP_PLATFORMS_ORGANIZATION_URL,
  ORGANIZATION_EMAIL_CONFIRMATION_LANDING_PAGE: env.REACT_APP_ORGANIZATION_EMAIL_CONFIRMATION_LANDING_PAGE ?? '',
  REQUEST_FEAUTE_URL: env.REACT_APP_REQUEST_FEAUTE_URL,
  USER_MANUAL_URL: env.REACT_APP_USER_MANUAL_URL,
  TERMS: env.REACT_APP_TERMS,
  PRIVACY: env.REACT_APP_PRIVACY,
  TRANSLATION_FILES_BASE_URL: env.REACT_APP_TRANSLATION_FILES_BASE_URL,
  BRANDING_THEME_URL: env.REACT_APP_BRANDING_THEME_URL,
  BRANDING_LOGO_URL: env.REACT_APP_BRANDING_LOGO_URL,
  BRANDING_FAVICON_32: env.REACT_APP_BRANDING_FAVICON_32_URL,
  BRANDING_FAVICON_APPLE_TOUCH_256: env.REACT_APP_BRANDING_FAVICON_APPLE_TOUCH_256_URL,
  IDLE_TIMEOUT_MS: env.REACT_APP_IDLE_TIMEOUT_MS,
  EPIC_RELEASE_FLAGS_URL: env.REACT_APP_EPIC_RELEASE_FLAGS_URL,
  CODE_SNIPPET_IFRAME_URL: env.REACT_APP_CODE_SNIPPET_IFRAME_URL,
  CODE_SNIPPET_TIMEOUT_IN_MS: env.REACT_APP_CODE_SNIPPET_TIMEOUT_IN_MS || 5000,
  // TODO: remove || 'int' when SOFT-7828 is done
  ENVIRONMENT: env.REACT_APP_ENVIRONMENT || 'int',
  CODE_SNIPPET_IFRAME_CREATION_MAX_RETRIES: env.REACT_APP_CODE_SNIPPET_IFRAME_CREATION_MAX_RETRIES || 3,
});

export default APP_CONFIG;
