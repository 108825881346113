import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import APP_CONFIG from 'src/config/AppConfig';
import { getThemeOverrideIfExists } from 'src/themes/theme';
import LocaleProvider from 'src/components/LocaleProvider';
import { AppBrandingHandler } from 'src/branding/AppBrandingHandler';

import { SessionExpiredModal, Theme, useIdleTimer } from '@biotmed/base-components';

import {
  attachCrudComponentRenderer,
  dataComponentsConfig,
  EntityType,
  EntityTypeEnum,
  pageTypeToActionsMapper,
  expandCrudComponentRenderer,
  expandCrudSliceMapper,
  PortalTypeEnum,
  pageTypeToCrudActionsToggleMapper,
  CrudActionsEnum,
  PageType,
  PageTypeEnum,
  DataComponentsParamsProvider,
} from '@biotmed/data-components';
import { utilsConfig } from '@biotmed/utils';

import GlobalStyle from '../../global.styled';
import AppComponent from '../Navigation';
import { selectors as loginSelectors, actions as loginActions } from '../../redux/data/login/modules/slice';
import { selectors as userSelectors } from '../../redux/data/user/modules/slice';

interface AppProps {}

const organizationEmailConfirmationLandingPage = () => {
  return APP_CONFIG.ORGANIZATION_EMAIL_CONFIRMATION_LANDING_PAGE;
};

const hiddenInputFieldsMapper = (entityType: EntityType | undefined) => {
  if (
    [
      EntityTypeEnum.PATIENT,
      EntityTypeEnum.CAREGIVER,
      EntityTypeEnum.ORGANIZATION_USER,
      EntityTypeEnum.USAGE_SESSION,
    ].includes(entityType as EntityTypeEnum)
  ) {
    return ['_ownerOrganization'];
  }
  return [];
};

const promptTimeoutInMs = 30000;

const App: React.FC<AppProps> = () => {
  const dispatch = useDispatch();
  const isLoggedIn: boolean = useSelector(loginSelectors.getIsLoggedIn);
  const userData = useSelector(userSelectors.getUserDetails);

  const [idleTimerManager] = useIdleTimer({
    isLoggedIn,
    timeout: APP_CONFIG.IDLE_TIMEOUT_MS,
    promptTimeout: promptTimeoutInMs, // The time when the user becomes idle until the onIdle function is called
    onIdle: () => dispatch(loginActions.logout()),
  });

  useEffect(() => {
    dataComponentsConfig.init({
      applicationType: PortalTypeEnum.MANUFACTURER_PORTAL,
      emailConfirmationLandingPage: organizationEmailConfirmationLandingPage,
      organizationEmailConfirmationLandingPage,
      expandCrudComponentRenderer,
      attachCrudComponentRenderer,
      expandCrudSliceMapper,
      hiddenInputFieldsMapper,
      actionsMapper: pageTypeToActionsMapper,
      getEnvironment: () => APP_CONFIG.ENVIRONMENT,
      crudActionsToggleMapper: (pageType?: PageType) => {
        const mapper = pageTypeToCrudActionsToggleMapper(pageType);
        if (pageType === PageTypeEnum.USAGE_SESSION) {
          return { ...mapper, [CrudActionsEnum.ATTACH]: false, [CrudActionsEnum.DETACH]: false };
        }
        if (pageType === PageTypeEnum.ORGANIZATION) {
          return {
            ...mapper,
            [CrudActionsEnum.ATTACH]: false,
            [CrudActionsEnum.DETACH]: false,
          };
        }
        return mapper;
      },
      idleTimerManager: {
        pause: idleTimerManager?.pause,
        activate: idleTimerManager?.activate,
      },
    });
  }, []);

  useEffect(() => {
    utilsConfig.init({
      codeSnippetIframeUrl: APP_CONFIG.CODE_SNIPPET_IFRAME_URL,
      codeSnippetTimoutInMS: APP_CONFIG.CODE_SNIPPET_TIMEOUT_IN_MS,
      codeSnippetIframeCreationMaxRetries: APP_CONFIG.CODE_SNIPPET_IFRAME_CREATION_MAX_RETRIES,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      idleTimerManager?.reset();
    }
  }, [isLoggedIn]);

  const [theme, setTheme] = useState<Theme>();

  useEffect(() => {
    async function initTheme() {
      const updatedTheme = await getThemeOverrideIfExists();
      setTheme(updatedTheme);
    }

    initTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      {theme && (
        <ThemeProvider theme={theme}>
          <DataComponentsParamsProvider currentLoggedInUserData={userData}>
            <LocaleProvider>
              <>
                <AppBrandingHandler />
                <GlobalStyle />
                <SessionExpiredModal
                  open={!!idleTimerManager?.isPrompted}
                  onContinue={() => idleTimerManager?.activate()}
                  onLogOff={() => {
                    dispatch(loginActions.logout());
                    idleTimerManager?.setIsPrompted(false);
                  }}
                  countdownTimeout={promptTimeoutInMs}
                />
                <AppComponent />
              </>
            </LocaleProvider>
          </DataComponentsParamsProvider>
        </ThemeProvider>
      )}
    </BrowserRouter>
  );
};

export default App;
