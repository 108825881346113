import {
  OrganizationCrud,
  organizationCrudReducer,
  organizationCrudSaga,
  organizationCrudStateKey,
  organizationCrudGetInitialState,
} from '@biotmed/data-components';

export { organizationCrudReducer, organizationCrudSaga, organizationCrudStateKey, organizationCrudGetInitialState };

export default OrganizationCrud;
