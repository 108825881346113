import React from 'react';
import { useIntl } from 'react-intl';
import { Icon } from '@biotmed/base-components';
import { AppName, LogoContainer } from './AppLogo.styled';
import { ReactComponent as LogoIcon } from '../../images/manufacturer-logo.svg';

export interface AppLogoProps {}

const AppLogo = () => {
  const intl = useIntl();

  return (
    <LogoContainer>
      <Icon height="25px" width="auto" IconComponent={LogoIcon} />
      <AppName>
        {intl.formatMessage({
          id: 'app-layout.top-menu.app-name.manufacturer',
          defaultMessage: 'Manufacturer',
        })}
      </AppName>
    </LogoContainer>
  );
};

export default AppLogo;
