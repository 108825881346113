import { fork, all } from 'redux-saga/effects';
import { saga as authSaga } from '@biotmed/auth-pages';
import { combinedCrudSagas } from '@biotmed/data-components';
import { visibilitySaga } from '@biotmed/base-components';
import { saga as dataSaga } from '../data';

/*
 * The entry point for all general sagas used in this application.
 */
export default function* root() {
  yield all([fork(dataSaga), fork(combinedCrudSagas), fork(authSaga), fork(visibilitySaga)]);
}
