import styled from 'styled-components';
import { convertTypographySettingsToCss, fontWeightNameMappings, Button } from '@biotmed/base-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
export const StyledLayout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
export const MainContent = styled.div`
  flex: 1;
  background: ${props => props.theme.palette.grayScale.lightest};
  overflow-y: auto;
  overflow-x: hidden;
  .generic-table-crud-page {
    height: 100%;
  }
`;
export const Spacer = styled.div`
  flex-grow: 2;
`;
export const Header = styled.div`
  flex: 0;
  height: 53px;
  background: ${props => props.theme.palette.grayScale.lightest};
  ${props => convertTypographySettingsToCss(props.theme.typography.body1)};
`;
export const Footer = styled.div`
  padding: 20px;
  padding-top: 0;
  flex: 0;
`;
export const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
export const UserName = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.body2)};
  font-weight: ${fontWeightNameMappings.light};
  line-height: 1.3;
`;
export const UserRole = styled.div`
  ${props => convertTypographySettingsToCss(props.theme.typography.button.small)};
  font-size: 1rem;
  font-weight: ${fontWeightNameMappings.light};
  line-height: 1.1;
`;

export const TopMenu = styled.div`
  flex: 1;
`;

export const UserDetails = styled.div``;

export const StyledIconMenuItem = styled(Button)`
  width: 160px;
  &&&.MuiButton-text {
    ${props => convertTypographySettingsToCss(props.theme.typography.body2)};
    color: ${props => props.theme.palette.grayScale.darkest};
    display: flex;
    justify-content: start;
  }
  &.MuiButton-root {
    padding: 10px;
    border-radius: 0px;
    && :hover {
      background-color: ${props => props.theme.palette.grayScale.medium};
    }
  }
`;
