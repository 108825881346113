/* eslint-disable no-console */

import { standardTheme } from '@biotmed/base-components';
import _ from 'lodash';
import APP_CONFIG from 'src/config/AppConfig';

export const getThemeOverrideIfExists = async () => {
  if (APP_CONFIG.BRANDING_THEME_URL) {
    let themeJson;
    try {
      const themeFile = await fetch(`${APP_CONFIG.BRANDING_THEME_URL}`);
      try {
        themeJson = await themeFile.json();
        return _.merge(standardTheme, themeJson);
      } catch (ex) {
        console.error('Theme file JSON is unparsable');
      }
    } catch (ex) {
      console.debug('Failed to fetch external theme file');
    }
  }

  return standardTheme;
};
