// eslint-disable-next-line no-use-before-define
import React, { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppLayout from 'src/components/AppLayout';
import { useSelector } from 'react-redux';
import Devices from 'src/routes/Devices';
import Organizations from 'src/routes/Organizations';
import {
  DataExportPage,
  TemplatesAdapter,
  templatesAdapterByOwnerOrganizationIdFactory,
  usePortalBuilder,
} from '@biotmed/data-components';
import { getDynamicRoutes } from 'src/utils/dynamicTabs';
import AuthRoutes from '../../Auth';
import { selectors } from '../../../redux/data/user';
import { Spin, SpinBox } from './styled';
import ChangePasswordPage from '../../ChangePasswordPage/ChangePasswordPage';
import RoutesEnum from '../modules/routes';
import { selectors as appSelectors } from '../../../redux/data/app';
import DeviceOperation from '../../DeviceOperation';

interface RoutePagesProps {
  isLoggedIn: boolean;
}

export const PublicPage: React.FC<RoutePagesProps> = props => {
  const { isLoggedIn } = props;

  return !isLoggedIn ? <AuthRoutes /> : <Navigate to="/" replace />;
};

export const PrivatePage: React.FC<RoutePagesProps> = props => {
  const { updatedBuilder } = usePortalBuilder({
    selector: appSelectors.selectMainViewBuilder,
    enabled: true,
  });

  const ownerOrganization = useSelector(selectors.selectOwnerOrganization);
  const syntheticOwnerOrganization = useMemo(
    () => (ownerOrganization ? { _ownerOrganization: ownerOrganization } : {}),
    [ownerOrganization],
  );

  const templatesAdapter: TemplatesAdapter | undefined = useMemo(() => {
    return ownerOrganization?.id ? templatesAdapterByOwnerOrganizationIdFactory(ownerOrganization.id) : undefined;
  }, [ownerOrganization]);

  const { isLoggedIn } = props;
  return isLoggedIn ? (
    <AppLayout>
      {!ownerOrganization ? (
        // TODO: Remove this loader when the backend supports sending ownerOrganization with the user object
        <SpinBox>
          <Spin />
        </SpinBox>
      ) : (
        <Routes>
          <Route
            caseSensitive
            path={`/${RoutesEnum.DEVICES}`}
            element={
              <Devices
                additionalCreationData={{
                  initialValues: { ...syntheticOwnerOrganization },
                }}
                templateProps={{ templatesAdapter }}
              />
            }
          />
          <Route caseSensitive path={`/${RoutesEnum.ORGANIZATIONS}`} element={<Organizations />} />
          <Route
            caseSensitive
            path={`/${RoutesEnum.DEVICE_OPERATION}`}
            element={
              <DeviceOperation
                additionalCreationData={{
                  initialValues: { ...syntheticOwnerOrganization },
                }}
                templateProps={{ templatesAdapter }}
              />
            }
          />

          {getDynamicRoutes(updatedBuilder?.tabs).map(tab => (
            <Route
              key={tab.path}
              caseSensitive
              path={tab.path}
              element={tab.render({
                additionalCreationData: {
                  initialValues: { ...syntheticOwnerOrganization },
                },
              })}
            />
          ))}
          <Route caseSensitive path={`/${RoutesEnum.CHANGE_PASSWORD}`} element={<ChangePasswordPage />} />
          <Route caseSensitive path={`/${RoutesEnum.DATA_EXPORT}`} element={<DataExportPage />} />
          <Route path="*" element={<Navigate to={`/${RoutesEnum.ORGANIZATIONS}`} replace />} />
        </Routes>
      )}
    </AppLayout>
  ) : (
    <Navigate to={{ pathname: `/${RoutesEnum.AUTH}` }} replace />
  );
};
