/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum RoutesEnum {
  AUTH = 'auth',
  DEVICES = 'devices',
  ORGANIZATIONS = 'organizations',
  CHANGE_PASSWORD = 'change-password',
  DEVICE_OPERATION = 'device-operation',
  DATA_EXPORT = 'data-export',
}

export default RoutesEnum;
