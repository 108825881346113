import React from 'react';
import { PlatformsPopper } from '@biotmed/base-components';
import { useIntl } from 'react-intl';
import { addLocaleToSearchParam } from '@biotmed/i18n';

import AppConfig from 'src/config/AppConfig';
import { useTheme } from 'styled-components';

interface PlatformsLinksMenuProps {}

export const PlatformsLinksMenu: React.FC<PlatformsLinksMenuProps> = () => {
  const intl = useIntl();
  const theme = useTheme();
  const platformsData = {
    console: {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.platforms-menu.console',
        defaultMessage: 'Console',
      }),
      link: AppConfig.PLATFORMS_CONSOLE_URL ? addLocaleToSearchParam(AppConfig.PLATFORMS_CONSOLE_URL) : '',
    },
    organization: {
      label: intl.formatMessage({
        id: 'app-layout.top-menu.platforms-menu.organization',
        defaultMessage: 'Organization Portal',
      }),
      link: AppConfig.PLATFORMS_ORGANIZATION_URL ? addLocaleToSearchParam(AppConfig.PLATFORMS_ORGANIZATION_URL) : '',
    },
  };
  return <PlatformsPopper platforms={platformsData} color={theme.palette.grayScale.darker3} />;
};

export default PlatformsLinksMenu;
