/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js';

// @ts-ignore
import UmsLogic, { USER_STORAGE_KEY } from '@biot/ums-js-logic';

import { initSdkApi } from '@biotmed/sdk-api-provider';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import intl from '@biotmed/i18n';

import { persistedStorage, getReloadOnLocalStorageChangeCallback } from '@biotmed/base-components';
import AppConfig from 'src/config/AppConfig';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import configureStore from './redux';
import { actions as loginActions } from './redux/data/login';
import App from './routes/Root';

// Create redux store with session state
const initialState = {};

const store = configureStore(initialState);

const onPersistentStorageChange = getReloadOnLocalStorageChangeCallback('userId');
persistedStorage.subscribeStorageChangesCallback({ key: USER_STORAGE_KEY, callback: onPersistentStorageChange });

UmsLogic.init({
  umsBaseURL: `${AppConfig.API_URL}/ums`,
  onRefreshTokenFail: () => {
    store.dispatch(loginActions.logoutFinish());
    // eslint-disable-next-line no-console
    console.log('refreshTokenFailed');
  },
  storage: persistedStorage,
});

initSdkApi({
  basePath: `${AppConfig.API_URL}`,
  tokenManager: UmsLogic,
  localeManager: {
    getLocale: () => {
      const { locale } = intl.current;
      return locale !== null ? locale : undefined;
    },
  },
});

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <App />
    </Provider>
  </QueryClientProvider>,
);
// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line global-require,import/no-extraneous-dependencies
  require('offline-plugin/runtime').install();
}
