import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { UserResponse } from '@biotmed/organization-sdk';
import { nameToString } from '@biotmed/data-components';
import { RootState } from '../../../reducer';
import { DATA_STATE_KEY } from '../../constants';

export const STATE_KEY = 'user';

interface UserState {
  userDetails: UserResponse | null;
}

/* eslint no-unused-vars: ["error", {"args": "none"}] */
export const getInitialState = (state?: any): UserState => ({
  userDetails: null,
});

/* eslint-disable no-param-reassign */
const slice = createSlice({
  name: STATE_KEY,
  initialState: getInitialState(),
  reducers: {
    userDetailsLoaded: (state, action: PayloadAction<UserResponse>) => {
      state.userDetails = action.payload;
    },
  },
});
/* eslint-enable no-param-reassign */

const getState = (state: RootState) => state[DATA_STATE_KEY][STATE_KEY] || getInitialState();

export const selectors = {
  getUserId: createSelector(getState, state => state.userDetails?._id),
  getUserDetails: createSelector(getState, state => state.userDetails),
  selectOwnerOrganization: createSelector(getState, state => state.userDetails?._ownerOrganization),
  selectUserDetails: createSelector(getState, state => state.userDetails),
  selectFullName: createSelector(getState, state => nameToString(state.userDetails?._name ?? null)),
  selectUserRole: createSelector(getState, state => state.userDetails?._template?.displayName),
  selectUserType: createSelector(getState, state => state.userDetails?._userType),
};

export const { actions } = slice;

const { reducer } = slice;
export default reducer;
