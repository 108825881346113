import React from 'react';
import { PoweredByBioT } from '@biotmed/base-components';
import { useSelector } from 'react-redux';
import { selectors } from 'src/redux/data/app';

export const AppFooter = () => {
  const biotVersion = useSelector(selectors.selectBiotVersion);
  return (
    <div>
      <PoweredByBioT version={biotVersion} />
    </div>
  );
};
export default AppFooter;
